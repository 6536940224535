import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxMaskModule } from 'ngx-mask';

import { StatusTabsComponent } from './components/status-tabs/status-tabs.component';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
		BrowserModule,
		IonicModule.forRoot({mode: 'md'}),
		IonicStorageModule.forRoot({name: '__1waiver'}),
		NgxMaskModule.forRoot(),
		HttpClientModule, AppRoutingModule
	],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
