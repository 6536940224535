import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'waivers',
    pathMatch: 'full',
  },
  {
    path: 'kiosk',
    loadChildren: () => import('./kiosk/kiosk.module').then( m => m.KioskPageModule)
  },
  {
    path: 'browser',
    loadChildren: () => import('./browser/browser.module').then( m => m.BrowserPageModule)
  },
  {
    path: 'event-error',
    loadChildren: () => import('./event-error/event-error.module').then( m => m.EventErrorPageModule)
  },
  {
    path: 'minor',
    loadChildren: () => import('./minor/minor.module').then( m => m.MinorPageModule)
  },
  {
    path: 'sign',
    loadChildren: () => import('./sign/sign.module').then( m => m.SignPageModule)
  },
  {
    path: 'signature',
    loadChildren: () => import('./signature/signature.module').then( m => m.SignaturePageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then( m => m.VideoPageModule)
  },
  {
    path: 'waivers',
    loadChildren: () => import('./waivers/waivers.module').then( m => m.WaiversPageModule)
  },
  {
    path: 'complete',
    loadChildren: () => import('./complete/complete.module').then( m => m.CompletePageModule)
  },
  {
    path: 'stop-sign',
    loadChildren: () => import('./stop-sign/stop-sign.module').then( m => m.StopSignPageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
